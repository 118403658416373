import Banner from "src/components/Banner";
import { Button, Container } from "src/styles/styles.global";
import Navbar from "src/components/Navbar";
import { AiFillCaretRight } from "react-icons/ai";
import styled from "styled-components";
import { colors } from "src/theme";
import { Fragment, useState } from "react";
import Community from "src/components/JoinTheCommunity";
import Footer from "src/components/Footer";
import Bg from "src/assets/particlesBg.jpeg";

const details: string[] = [
  "Pancake Swap",
  "BSC Scan",
  "POOCOIN",
  "Coin Market",
  "Dex.guru",
];

const Home = () => {
  const [activeText, setActiveText] = useState<string>("POOCOIN");
  return (
    <HomeContainer>
      <Navbar />
      <Banner />
      <Details img={Bg}>
        <Container
          padding="20px 0 40px"
          borderBottom={`.5px solid ${colors.LINE}`}
          flex
          flexDirection="column"
          alignItem="center"
          jc="space-around"
        >
          <div>
            <Title>
              What is <span>CREO?</span>
            </Title>
            <Text>
              CREO (a word meaning “I create” in Italian, and “I think” in
              Spanish) is a BSC community centered around active minds
              constantly thinking, creating, buidling and adding value to the
              BSC ecosystem. It’s a community by creators, for creators with
              something to offer to all creatives; from the goofiest of meme
              makers to the Chaddest devs.
              <br /> <br /> The primary token of the CREO community is $CREO.
              The CREO community was created by a number of Chads to fill the
              void of legitimate BSC projects, as the BSC community has recently
              become synonymous with rug-pulls.
            </Text>
          </div>
          <TradeButton
            width="150px"
            height="35px"
            br="5px"
            bg="transparent"
            border={`.5px solid ${colors.PRIMARY}`}
          >
            Learn More
          </TradeButton>
          {/* <HR /> */}
        </Container>
      </Details>
      <Details img={Bg} height="40vh">
        <Container
          padding="20px 0"
          borderBottom={`.5px solid ${colors.LINE}`}
          flex
          flexDirection="column"
          alignItem="center"
          jc="center"
        >
          <Title mb="1rem">
            Project <span>Details</span>
          </Title>

          <DetailsButtonCover>
            {details.map((detail) => (
              <Fragment key={detail}>
                <TradeButton
                  width="150px"
                  height="40px"
                  br="5px"
                  bg={activeText === detail ? colors.PRIMARY : colors.BLUE}
                  border={`1px solid ${colors.PRIMARY}`}
                  margin="0 10px 20px"
                  onClick={() => setActiveText(detail)}
                >
                  {detail}{" "}
                  <Span>
                    <AiFillCaretRight />
                  </Span>
                </TradeButton>
              </Fragment>
            ))}
          </DetailsButtonCover>
          {/* <HR /> */}
        </Container>
      </Details>
      <Community />
      <Footer />
    </HomeContainer>
  );
};

export default Home;

const HomeContainer = styled.div`
  width: 100%;
  background-color: url(${({ theme }) => theme.colors.BLUE});
`;

const Details = styled.div<{ height?: string; img: string }>`
  background-image: url(${({ img }) => img});
  color: ${({ theme }) => theme.colors.WHITE};
  padding: 10px;
  width: 100%;
  height: ${({ height }) => height ?? "90vh"};

  @media ${({ theme }) => theme.screens.SMALL_TABLET} {
    /* height: fit-content;
    padding: 1rem 10px; */
  }
`;

const Title = styled.h1<{ mb?: string }>`
  font-size: 45px;
  font-weight: 700;
  text-align: center;
  margin-bottom: ${({ mb }) => mb ?? "2rem"};
  span {
    color: ${({ theme }) => theme.colors.PRIMARY};
  }

  @media ${({ theme }) => theme.screens.SMALL_TABLET} {
    font-size: 35px;
  }
`;

const Text = styled.p`
  text-align: center;
  width: 60%;
  margin: 1rem auto;

  @media ${({ theme }) => theme.screens.SMALL_TABLET} {
    width: 90%;
    margin: 2rem auto;
  }
`;

const Span = styled.span`
  margin-left: 15px;
`;

const TradeButton = styled(Button)``;

const DetailsButtonCover = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
  flex-wrap: wrap;
`;

// const HR = styled.hr`
//   border: ${({ theme }) => `1px solid ${theme.colors.LINE}`};
//   width: 100%;
//   margin: 2rem auto;
// `;
