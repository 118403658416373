import { Button, Container } from "src/styles/styles.global";
import { GiHamburgerMenu } from "react-icons/gi";
import CreoLogo from "src/assets/logo.png";
import styled from "styled-components";
import { Fragment } from "react";
import { colors } from "src/theme";
import useWindowSize from "src/hooks/useWindowSize";
import DrawerComponent from "./DrawerComponent";
import { useDisclosure } from "@chakra-ui/hooks";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [width] = useWindowSize();
  const isMobile = width < 768;
  const navDrawer = useDisclosure();
  return (
    <NavContainer>
      <Container flex>
        <Link to="/">
          <LogoCover>
            <Logo src={CreoLogo} alt="logo" />
            <LogoText>CREO</LogoText>
          </LogoCover>
        </Link>
        {!isMobile ? (
          <Fragment>
            <NavLinks>
              <StyledLink to="/discover-creo" active>
                Discover Creo
              </StyledLink>
              <StyledLink to="/roadmap">Roadmap</StyledLink>
              <StyledLink to="/project-details">Project Details</StyledLink>
              <StyledLink to="/blog">Blog</StyledLink>
            </NavLinks>
            <TradeButton width="150px" height="30px" br="5px">
              <a
                href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xcb95881d76f93a401bbd47a2eb69fc33e9476795"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Start Trading Creo{" "}
              </a>
            </TradeButton>
          </Fragment>
        ) : (
          <GiHamburgerMenu
            onClick={navDrawer.onOpen}
            color={colors.WHITE}
            size={20}
            className="pointer"
          />
        )}
      </Container>

      <DrawerComponent
        header=""
        controllers={navDrawer}
        direction="right"
        bgColor={colors.DARK}
        closeIconColor={colors.WHITE}
      >
        <Fragment>
          <NavLinks>
            <StyledLink to="/discover-creo" active>
              Discover Creo
            </StyledLink>
            <StyledLink to="/roadmap">Roadmap</StyledLink>
            <StyledLink to="/project-details">Project Details</StyledLink>
            <StyledLink to="/blog">Blog</StyledLink>
          </NavLinks>
          <TradeButton width="150px" height="30px" br="5px">
            Start Trading Creo
          </TradeButton>
        </Fragment>
      </DrawerComponent>
    </NavContainer>
  );
};

export default Navbar;

const NavContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.DARK};
  color: ${({ theme }) => theme.colors.WHITE};
  padding: 10px 30px;
  width: 100%;
  position: sticky;
  top: 0;
  transition: all 1s ease;
  z-index: 100;

  @media ${({ theme }) => theme.screens.SMALL_TABLET} {
    padding: 10px;
  }
`;

const LogoCover = styled.div`
  display: flex;
  align-items: center;
  padding-right: 15px;
  border-right: ${({ theme }) => `1px solid ${theme.colors.LINE}`};
`;

const Logo = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 10px;
`;

const LogoText = styled.p`
  font-size: 13px;
  width: fit-content;
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;

  @media ${({ theme }) => theme.screens.SMALL_TABLET} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledLink = styled(Link)<{ active?: boolean }>`
  &:not(:last-child) {
    margin-right: 30px;
  }
  color: ${({ theme, active }) => active && theme.colors.PRIMARY};
  cursor: pointer;
  @media ${({ theme }) => theme.screens.SMALL_TABLET} {
    margin: 1rem 0;
    color: ${({ theme, active }) =>
      active ? theme.colors.PRIMARY : theme.colors.WHITE};
  }
`;

const TradeButton = styled(Button)`
  @media ${({ theme }) => theme.screens.SMALL_TABLET} {
    margin: 2rem 0;
  }
`;
