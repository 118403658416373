// global.js
import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`

@font-face {
  font-family: 'Poppins Medium';
  src: url('../fonts/poppins/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Bold';
  src: url('../fonts/poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins SemiBold';
  src: url('../fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Light';
  src: url('../fonts/poppins/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Regular';
  src: url('../fonts/poppins/Poppins-Regular.ttf') format('truetype');
}

  html, body{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  body{
    font-size: 14px;
    font-family: ${({ theme }) => theme.fonts.POPPINS_REGULAR};
    input:-webkit-autofill,
	  input:-webkit-autofill:hover,
  	input:-webkit-autofill:focus,
	  input:-webkit-autofill:active {
		transition: background-color 5000s ease-in-out 0s;
		/* -webkit-text-fill-color: transparent; */
	  }
  }

  a,button,input, select, text-area, div, p, li, h1, h2, h3, h4, h5, h6, span{
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    letter-spacing: 0.5px;
  }

  p, h1, h2, h3, h4, h5, h6{
    margin: 0
  }

  .pointer {
    cursor: pointer;
  }

  ul {
    list-style: none;
    margin:0;
    padding:0;
  }
`;
