import { Container } from "src/styles/styles.global";
import { colors } from "src/theme";
import styled from "styled-components";
import Telegram from "src/assets/telegram.png";
import Discord from "src/assets/discord.png";
import Twitter from "src/assets/twitter.png";
import Bg from "src/assets/particlesBg.jpeg";

const Community = () => {
  return (
    <CommunityContainer img={Bg}>
      <Container
        flex
        flexDirection="column"
        alignItem="center"
        jc="center"
        borderBottom={`1px solid ${colors.LINE}`}
      >
        <Title>
          Join <span>The</span> Community
        </Title>
        <IconCover>
          <Icon src={Twitter} alt="twitter" />
          <Icon src={Telegram} alt="telegram" />
          <Icon src={Discord} alt="discord" />
        </IconCover>
      </Container>
    </CommunityContainer>
  );
};

export default Community;

const CommunityContainer = styled.div<{ img?: string }>`
  background-image: url(${({ img }) => img});
  color: ${({ theme }) => theme.colors.WHITE};
  padding: 10px;
  width: 100%;
  height: 40vh;

  @media ${({ theme }) => theme.screens.SMALL_TABLET} {
    height: 30vh;
  }
`;

const Title = styled.h1`
  font-size: 45px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
  span {
    color: ${({ theme }) => theme.colors.PRIMARY};
  }

  @media ${({ theme }) => theme.screens.SMALL_TABLET} {
    font-size: 35px;
  }
`;

const IconCover = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 2rem;
  }
`;

// const HR = styled.hr`
//   border: ${({ theme }) => `1px solid ${theme.colors.LINE}`};
//   width: 100%;
//   margin: 2rem auto;
// `;
