import ThemeProvider from "./theme";
import { GlobalStyles } from "./styles";
import { ChakraProvider } from "@chakra-ui/react";
import Routes from "./routes/Routes";

const App = () => {
  return (
    <>
      <ThemeProvider>
        <GlobalStyles />
        <ChakraProvider>
          <Routes />
        </ChakraProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
