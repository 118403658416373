import { Container } from "src/styles/styles.global";
import CreoLogo from "src/assets/logo.png";
import { Link } from "react-router-dom";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import styled from "styled-components";
import { colors } from "src/theme";
import Bg from "src/assets/particlesBg.jpeg";

const Footer = () => {
  return (
    <FooterContainer img={Bg}>
      <Container flex wrap>
        <LogoCover>
          <Logo src={CreoLogo} alt="logo" />
          <LogoText>CREO</LogoText>
        </LogoCover>
        <RightLists>
          <Lists>
            <List>
              <ListLink to="/">Home</ListLink>
            </List>
            <List>
              <ListLink to="/farm">Farm</ListLink>
            </List>
            <List>
              <a
                href="https://exchange.pancakeswap.finance/#/add/BNB/0xcB95881D76f93a401BBd47A2eB69FC33e9476795"
                target="_blank"
                rel="noreferrer"
              >
                Add Liquidity
              </a>
            </List>
          </Lists>
          <Lists>
            <List>
              <a
                href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xcb95881d76f93a401bbd47a2eb69fc33e9476795"
                target="_blank"
                rel="noreferrer"
              >
                Buy Creo
              </a>
            </List>
            <List>
              <ListLink to="/governance">Governance</ListLink>
            </List>
          </Lists>
          <Lists>
            <List>Join Newsletter</List>
            <List>Subscribe to our newsletter to get more information</List>
            <List>
              <InputGroup>
                <Input placeholder="Enter your email" type="email" />
                <InputRightElement
                  children={<Dot bgColor={colors.PRIMARY} />}
                />
              </InputGroup>
            </List>
          </Lists>
        </RightLists>
      </Container>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled.div<{ img?: string }>`
  background-image: url(${({ img }) => img});
  color: ${({ theme }) => theme.colors.WHITE};
  width: 100%;
  padding: 1rem 10px 2rem;
`;

const LogoCover = styled.div`
  text-align: center;
  width: fit-content;
  margin-right: 1rem;
  margin-bottom: 1rem;
`;

const LogoText = styled.p``;

const Logo = styled.img`
  width: 75px;
  margin-bottom: 5px;
`;

const Lists = styled.ul`
  min-width: 100px;
  margin-bottom: 1rem;
`;

const ListLink = styled(Link)`
  margin-bottom: 10px;
  cursor: pointer;
`;

const List = styled.li`
  margin-bottom: 10px;
  cursor: pointer;
`;

const RightLists = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 70%;
  flex-wrap: wrap;
  min-width: 300px;

  @media ${({ theme }) => theme.screens.SMALL_TABLET} {
    width: 100%;
    padding: 10px;
  }
`;

const Dot = styled.div<{ bgColor?: string }>`
  background-color: ${({ bgColor }) => bgColor && bgColor};
  width: 20px;
  height: 20px;
  border-radius: 100%;
`;
