// theme.js
enum SCREENS {
  DESKTOP = "(max-width: 1440px)",
  WIDESCREEN = "(max-width: 1200px)",
  LARGE_TABLET = "(max-width: 1024px)",
  MEDIUM_TABLET = "(max-width: 900px)",
  SMALL_TABLET = "(max-width: 768px)",
  LARGE_MOBILE = "(max-width: 600px)",
  MEDIUM_MOBILE = "(max-width: 480px)",
  SMALL_MOBILE = "(max-width: 320px)",
}

// All app colors
enum LIGHT_MODE_COLORS {
  DARK = "#171717",
  PRIMARY = "#06BFBF",
  WHITE = "#FFF",
  LINE = "#71839D",
  BANNER = "#141e24",
  BLUE = "#000c32",
}

enum DARK_MODE_COLORS {
  DARK = "#171717",
  PRIMARY = "#06BFBF",
  WHITE = "#FFF",
  LINE = "#71839D",
  BANNER = "#141e24",
  BLUE = "#000c32",
}

// All app font sizes
enum FONTS {
  SMALL_SIZE = 8,
  MEDIUM_SIZE = 12,
  LARGE_SIZE = 16,
  POPPINS_LIGHT = "Poppins Light",
  POPPINS_REGULAR = "Poppins Regular",
  POPPINS_MEDIUM = "Poppins Medium",
  POPPINS_SEMI_BOLD = "Poppins SemiBold",
  POPPINS_BOLD = "Poppins Bold",
}

enum SHADOWS {
  S1 = "0px 1px 5px rgba(255, 255, 255, 0.25)",
}

enum Z_INDEX {}

enum SHAPE {
  BORDER_RADIUS = "7.5px",
}

export const styledComponentTheme = {
  fonts: FONTS,
  screens: SCREENS,
  shadows: SHADOWS,
  zIndex: Z_INDEX,
  shape: SHAPE,
  DARK_MODE_COLORS,
  LIGHT_MODE_COLORS,
};
