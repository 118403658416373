import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
} from "@chakra-ui/react";
import { FC, PropsWithChildren } from "react";

export type TModalController = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const DrawerComponent: FC<
  PropsWithChildren<{
    direction: "right" | "left" | "top" | "bottom";
    controllers: TModalController;
    header?: React.ReactNode;
    bgColor?: string;
    minWidth?: string;
    zIndex?: number;
    flexHeaderRight?: boolean;
    closeLeftStyle?: string;
    closeTopStyle?: string;
    hideCloseButton?: boolean;
    closeIconColor?: string;
  }>
> = ({
  children,
  direction,
  controllers,
  header,
  bgColor,
  minWidth,
  zIndex,
  flexHeaderRight,
  closeLeftStyle,
  closeTopStyle,
  hideCloseButton,
  closeIconColor,
}) => {
  return (
    <Drawer
      isOpen={controllers.isOpen}
      placement={direction}
      onClose={controllers.onClose}
    >
      <DrawerOverlay>
        <DrawerContent
          style={{
            backgroundColor: bgColor ?? "#fff",
            minWidth,
            zIndex,
          }}
        >
          {!hideCloseButton && (
            <DrawerCloseButton
              color={closeIconColor && closeIconColor}
              style={{ left: closeLeftStyle, top: closeTopStyle }}
            />
          )}
          <DrawerHeader
            style={{
              display: flexHeaderRight ? "flex" : "",
              justifyContent: flexHeaderRight ? "flex-end" : "",
            }}
          >
            {header}
          </DrawerHeader>

          <DrawerBody>{children}</DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default DrawerComponent;
