import styled from "styled-components";

const Container = styled.div<{
  flex?: boolean;
  flexDirection?: string;
  jc?: string;
  alignItem?: string;
  borderBottom?: string;
  wrap?: boolean;
  padding?: string;
}>`
  max-width: 1200px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: ${({ flex }) => flex && "flex"};
  flex-direction: ${({ flexDirection }) => flexDirection && flexDirection};
  justify-content: ${({ flex, jc }) => flex && (jc ?? "space-between")};
  align-items: ${({ flex, alignItem }) => flex && (alignItem ?? "center")};
  border-bottom: ${({ borderBottom }) => borderBottom && borderBottom};
  flex-wrap: ${({ wrap }) => wrap && "wrap"};
  padding: ${({ padding }) => padding && padding};
`;

const Button = styled.button<{
  onClick?: any;
  width?: string;
  margin?: string;
  height?: string;
  br?: string;
  color?: string;
  bg?: string;
  border?: string;
}>`
  background: ${({ theme, bg }) => bg ?? theme.colors.PRIMARY};
  color: ${({ color }) => color && color};
  height: ${({ height }) => height ?? "40px"};
  width: ${({ width }) => width ?? "138px"};
  border-radius: ${({ theme, br }) => br ?? theme.shape.BORDER_RADIUS};
  box-shadow: ${({ theme }) => theme.shadows.S1};
  border: ${({ border }) => border ?? "none"};
  cursor: pointer;
  margin: ${({ margin }) => margin ?? 0};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  transition: all 1s ease;
  line-height: 0;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background: ${({ theme, color, bg }) =>
      bg !== "transparent" && (color ?? theme.colors.WHITE)};
    border-radius: ${({ theme, br }) => br ?? theme.shape.BORDER_RADIUS};

    transition: transform 300ms ease-in-out;
    transform: scaleX(0);
    transform-origin: left;
  }

  &:hover::before,
  &:focus::before {
    transform: scaleX(1);
  }

  transition: color 300ms ease-in-out;
  z-index: 1;

  &:hover,
  &:focus {
    color: white;
    color: ${({ theme, bg }) =>
      bg === "transparent"
        ? theme.colors.WHITE
        : bg
        ? bg
        : theme.colors.PRIMARY};
    outline: none;
  }

  &:disabled {
    box-shadow: none;
    cursor: default;
  }

  @media ${({ theme }) => theme.screens.LARGE_MOBILE} {
    height: 33px;
  }
`;

export { Button, Container };
