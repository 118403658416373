import Home from "../pages/Home";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import ComingSoon from "src/pages/ComingSoon";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/discover-creo" component={ComingSoon} />
        <Route exact path="/roadmap" component={ComingSoon} />
        <Route exact path="/project-details" component={ComingSoon} />
        <Route exact path="/blog" component={ComingSoon} />
        <Route exact path="/farm" component={ComingSoon} />
        <Route exact path="/governance" component={ComingSoon} />
      </Switch>
    </Router>
  );
};

export default Routes;
