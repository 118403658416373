import Footer from "src/components/Footer";
import Navbar from "src/components/Navbar";
import styled from "styled-components";
import Bg from "src/assets/particlesBg.jpeg";

const ComingSoon = () => {
  return (
    <PageContainer img={Bg}>
      <Navbar />
      <h1>Coming Soon</h1>
      <Footer />
    </PageContainer>
  );
};

export default ComingSoon;

const PageContainer = styled.div<{ img: string }>`
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url(${({ img }) => img});

  h1 {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    letter-spacing: 2px;
    font-weight: 800;
    color: #fff;
  }

  @media (max-width: 770px) {
    h1 {
      font-size: 45px;
    }
  }
`;
